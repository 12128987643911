import style from './PostResumeStyle.module.scss'
import {useState} from "react";
import useNotification from "../../hooks/useNotification.hook";
import Nota from "./Nota";
import {useMessage} from "../../hooks/message.hook";
import NewsService from "../../services/NewsService";
import {useEffect} from "react";

function EntryProgram ({program,setActivemodal}){

    const [myname, setMyname] = useState('')
    const [phone, setPhone] = useState('')
    const [date, setDate] = useState('')
    const { notifications, addNotification } = useNotification();
    const [active, setActive] = useState(false)
    const [thisContacts, setThisContacts] = useState([]);

    const message = useMessage();

    const postMess = () => {
        if(myname.length>0 && phone.length>0){
            setActivemodal(false)
            setMyname('')
            setPhone('')
            setDate('')
            message('Ожидайте звонка для подтверждения')
        }
    }

    const getCities = async () => {
        try{
            const {data} = await NewsService.getCities({capter: 'hopekids'})
            setThisContacts(data)
        }catch(e){
            console.log(e)
        }
    }

    const postMess2 = async () => {

        const redy = 12
        const email = thisContacts[0].email[0]
        if(redy === 11){
            console.log(program.name)
            console.log(program.price)
            console.log(myname)
            console.log(phone)
            console.log(date)
            console.log(email)
        }else{
            try {
                const payload = {
                    name: myname,
                    email: 'zakaz@gk-omedia.ru',
                    message: `Программа: "${program.name}" стоимостью: ${program.price} руб., Дата: ${date}`,
                    phone: phone,
                    recipient: email, // Email получателя
                };

                // Отправка сообщения через сервис
                const response = await NewsService.sendContactMessage(payload);

                if (response.data) {
                    setActivemodal(false)
                    setMyname('')
                    setPhone('')
                    setDate('')
                    message('Ожидайте звонка для подтверждения')
                }
            } catch (e) {
                console.error(e);
                message('Ошибка при отправке заявки. Попробуйте снова.');
            }
        }


    };

    useEffect(()=>{
        getCities()
    }, [])

    return (
        <div className={style.main}>
            <div className={style.up}>
                <div className={style.title}>Запись на</div>
                <div className={style.vakname}>{program.name}</div>
                <div className={style.vakname}>Стоимость {program.price}</div>
                <input onChange={e=>setMyname(e.target.value)} value={myname} type="text" className={style.forminput} placeholder='Как Вас зовут'/>
                <input onChange={e=>setDate(e.target.value)} value={date} type="date" className={style.forminput} placeholder='Дата'/>
                <input onChange={e=>setPhone(e.target.value)} value={phone} type="number" className={style.forminput} placeholder='Телефон для связи'/>
            </div>
            <div className={style.down}>
                <div className={style.btnpost} onClick={postMess2}>Отправить</div>
            </div>



        </div>
    )
}

export default EntryProgram